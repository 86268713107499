// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import "~leaflet/dist/leaflet.css";

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$adria-project-front-primary: mat.define-palette(mat.$indigo-palette);
$adria-project-front-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$adria-project-front-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$adria-project-front-theme: mat.define-light-theme((
  color: (
    primary: $adria-project-front-primary,
    accent: $adria-project-front-accent,
    warn: $adria-project-front-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($adria-project-front-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; padding: 0; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; width: 100vw; }

.mat-mdc-form-field + .mat-mdc-form-field {
  margin-left: 8px;
}


.font-weight-bold {
  font-weight: bold;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255,255,255,0.8);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;
}

.info h4 {
  margin: 0 0 5px;
  color: #777;
}

.legend {
  text-align: left; line-height: 18px; color: #555;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.legend i {
  width: 18px;
  height: 18px;
  float: left;
  // margin-right: 8px;
  opacity: 0.7;
  width: 100%;
}

.color-number-legend {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
}

.mat-mdc-select-panel {
  // min-height: fit-content;
}


